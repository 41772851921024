@font-face {
  font-family: tsta;
  src: url("font/TSTARPRO-Light.otf") format("opentype");
}
@font-face {
  font-family: "HelveticaNeue";
  src: url("font/HelveticaNeue\ Light.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeue";
  src: url("font/HelveticaNeueBd.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeue";
  src: url("font/HelveticaNeue\ Thin.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

:root {
  --ciemnoszary: #323942;
  --szary: #657983;
  --pomarancz: #f26122;
}
.App {
  line-height: 150%;
  font-family: HelveticaNeue, Arial, Helvetica, sans-serif;
  font-weight: 200;
  font-size: 14px;
  letter-spacing: 1px;
  color: var(--ciemnoszary);
}

.clear {
  clear: both;
}

a:hover {
  text-decoration: underline !important;
}

/* header */
.header {
  max-width: 990px;
  margin: 10px auto 30px;
  z-index: 100;
  position: relative;
}
.logo {
  width: 240px;
  float: left;
}
.menu {
  margin: 76px 0px 10px 0px;
  float: right;
  font-size: 14px;
  width:690px
}
.menu a,
.menu .openSubMenu {
  margin: 0 13px;
  color: var(--szary);
  text-transform: uppercase;
  text-decoration: none;
  float: left;
}

.menu .sub {
  position: relative;
  float: left;
}
.subMenu a {
  color: var(--szary);
  text-transform: uppercase;
  text-decoration: none;
  margin: 0px;
}
.menu .openSubMenu {
  display: inline;
}
.menu .subMenu {
  position: absolute;
  top: 20px;
  left: 5px;
  padding: 10px;
  width: 170px;
  background-color: #fff;
  display: none;
}
.menu .sub:hover .subMenu {
  display: block;
}

.menu span{
  float:right;
}
.menu input {
  margin: 0 0 0 10px;
  background-color: #e7e7e8;
  border: 0px;
  padding: 5px;
  position: relative;
  top: -3px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
}

.fa-search {
  color: var(--szary);
  position: relative;
  left: -20px;
  top: -2px;
}

/* footer */
.footer {
  width: 100%;
  background-color: var(--szary);
  color: #fff;
  padding: 20px 0;
}

.footer_content {
  max-width: 960px;
  margin: 10px auto;
}

.footer_content div {
  box-sizing: content-box;
  width: 33.3%;
  float: left;
}

.footer h3 {
  margin: 0px;
  padding: 0px 0px 10px 0px;
  text-transform: uppercase;
  font-size: 16px
}

.footer input {
  font-style: italic;
  color:#323942;
  padding: 2px 5px;
}

.footer .newsletter-submit {
  font-weight: 600;
  padding-left: 5px;
  cursor: pointer;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

/* pages */
.page {
  clear: both;
  max-width: 960px;
  margin: 10px auto;
}

.pageHeadLine {
  background-color: var(--pomarancz);
  margin: 10px 0;
  padding: 0 20px;
  height: 15px;
}
.pageHeadLine div {
  color: var(--pomarancz);
  background-color: #fff;
  text-transform: uppercase;
  float: left;
  position: relative;
  top: -4px;
  padding: 0 7px;
}

.aboutContent, .aboutContentDirectors {
  padding-left: 90px;
  font-weight: 400;
  max-width: 480px;
  text-align: justify;
  text-justify: inter-word;
}
.aboutContent img{
  width:100%;
}
.aboutContentDirectors img{
  max-width:100%;
}

/* list */

.list {
  display: flex;
  flex-wrap: wrap;
}

.list a {
  color: var(--ciemnoszary);
  text-decoration: none;
  float: left;
  box-sizing: border-box;
  width: 31%;
  margin: 1%;
}
.list img {
  width: 100%;
}

/* single */
.singleHeader {
  width: 33.3%;
  float: left;
}
.singleImage {
  width: 50%;
  float: left;
  margin-top:5px;
  margin-bottom: 10px;
}
.singleImage img {
  width: 100%;
}

.personImage{
  width:auto!important;
}

h1 {
  font-family: tsta, Verdana, Geneva, Tahoma, sans-serif;
  font-size: 48px;
  line-height: 48px;
  font-weight: normal;
  letter-spacing: 2px;
  margin-top:10px
}
h2 {
  font-family: tsta, Verdana, Geneva, Tahoma, sans-serif;
  font-size: 24px;
  line-height: 24px;
  font-weight: normal;
  letter-spacing: 2px;
}
.list h2{
  margin-bottom: 10px;
  margin-top: 0px;
}
.second{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

strong {
  font-weight: 700;
}

.map {
  position: relative;
  width: 100%;
  height: 200px;
}
.map img {
  position: absolute;
}

.map img.Mapa_1 {
  width: 11%;
  left: 4%;
}
.map img.Mapa_2 {
  width: 17%;
  left: 17%;
}
.map img.Mapa_3 {
  width: 33%;
  left: 36%;
}
.map img.Mapa_4 {
  width: 12%;
  left: 69%;
  top: 70px;
}
.map img.Mapa_5 {
  width: 15%;
  left: 80%;
}
.toolTip {
  position: absolute;
  background-color: #fff;
  padding: 5px;
  margin: 5px;
  display: block;
  color: var(--ciemnoszary);
  z-index: 1000;
}

.more {
  padding: 5px 0;
  cursor: pointer;
}
.fa-bars {
  font-size: 12px;
}


@media (max-width: 990px){
	.list a {
    width: 48%;
    margin: 1%;
  }
  .logo {
    margin:5px;
    float: none;
  }
  .menu {
    width: 450px;
  }
  .menu a, .menu .openSubMenu {
    margin: 0 15px 0 0;
  }
  .menu .support{
    margin:0;
  }
  .footer_content div{
    width:100%;
    margin-bottom: 10px;
    text-align: center;
  }
  .menu span {
    display: none;
  }
}
@media (max-width: 750px){
	.list a {
    width: 98%;
    margin: 1%;
  }
  .menu{
    margin: 30px 0px 10px 5px;
    float: none;
  }
  .map{
    display:none;
  }
  .singleHeader{
    width:49%;
  }
}